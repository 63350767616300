
header{
    transition: transform 0.3s ease-in;
    box-sizing: border-box;
    position:fixed;
    z-index: 2;
    top: 0;
    width:100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    font-family: var(--font-style);
    background-color: var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 100);
    max-height: var(--header-size);
    backdrop-filter: blur(5px);

    div {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    img{
        width:6rem;
        border-radius: 50%;
        margin-right: auto;
    }
    p {
        font-size: 5rem;
    }
    ul {
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: space-between;
        margin-right: 0;

        li {
            display: flex;
            align-items: center; 
            justify-content: center;
            padding-inline: 1rem;
            list-style-type: none;
            img{
                height: 2rem;
                vertical-align: middle;
            }
            a {
                text-decoration: none;
                color: var(--text-color);
                margin: 0;
                vertical-align: middle;
            }
            a.activeLink {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}
.HeaderHide {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}


@media screen and (max-width: 1300px) {
    header{
        height:5rem;
        align-items: baseline;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            height: auto;
        
        }
        img{
            width:3rem;
            height:3rem;
        }
        ul {
            flex-wrap: wrap;
            font-size: small;

            li{
                .visuallyHidden{
                    display: none;
                }
                padding-inline: 0.5rem;
                img{
                    width: 1.5rem;
                }
                .Home{
                    background-image: url(../../assets/images/home.svg);
                }
                .Me{
                    background-image: url(../../assets/images/me.svg);
                }
                .Works{
                    background-image: url(../../assets/images/works.svg);
                }
                .Contact{
                    background-image: url(../../assets/images/contact.svg);
                }
                .MySpace{
                    background-image: url(../../assets/images/edit.svg);
                }
                .Logout{
                    background-image: url(../../assets/images/logout.svg);
                }
                .Login{
                    background-image: url(../../assets/images/login.svg);
                }
            }
        }
    }
}


