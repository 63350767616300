.Titlename {
    width: 100%;
    margin-top: var(--header-size);
    text-align: center;

    font-family: var(--font-style);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: appear 0.5s ease-in;

    h1 {
        font-weight: 600;
        font-size: 5rem;
    }
    h2 {
        font-weight: 400;
        font-size: 4rem;
    }
    p {
        font-size: 2rem;
    }

}

@keyframes appear {
    0% {
        transform: scaleY(0);
    }
    30%{
        transform: scaleY(1);
    }
    70%{
        transform: scaleX(0.9) scaleY(1.1) translateY(2rem);
    }
    90%{
        transform: scaleX(1.1) scaleY(0.9) translateY(0);
    }
    100% {
        transform: scaleX(1) scaleY(1);
    }
}

@media screen and (max-width:1300px) {
    .Titlename{
        h1 {
            font-weight: 600;
            font-size: 3rem;
        }
        h2 {
            font-weight: 200;
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
        }
    }
    
}
