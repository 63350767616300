section {
    box-sizing: border-box;
    max-width:50rem;

    margin: auto;
    margin-bottom: var(--header-size);
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: var(--element-color);
    border-radius: 3rem;
    box-shadow:  0 1rem 2rem rgba(0, 0, 0, 0.726);
    font-family: var(--font-style);
    h2 {
        font-weight: 400;
        font-size: 2rem;
    }
    p {
        font-size: 1.5rem;
    }
    a {
        text-decoration: none;
        color: var(--secondary-color);
        font-weight: 700;
    }
}
.Aboutmelink{
    padding-top: var(--header-size);
}

@media screen and (max-width: 1300px) {
    section{
        width:90%;
        h2 {
            font-weight: 200;
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
        }
        a {
            text-decoration: none;
            color: var(--secondary-color);
            font-weight: 700;
        }
    }
}