.projectslink {
	position: relative;
	padding-top: var(--header-size);
	margin-bottom: var(--header-size);
	justify-content: space-between;
	margin-inline: auto;
	width:90%;
	height: 100%;

	.projectsViewer {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;

		margin: auto;
		margin-top: 1rem;
		justify-content: center;
		align-items: stretch;
		height: 100%;
		color: var(--secondary-color);

		img {
			width: auto;
		}

		.projectItem {
			width:0%;
			display: none;
			opacity: 0;

			&.current {
				width:100%;
				display: block;
				opacity: 1;
				animation-name: fadeIn;
				animation-duration: 500ms;
			}
		}

		button {
			align-self: stretch; // Ajouté pour que les boutons prennent toute la hauteur
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        background-color: transparent;
			img {
				width: 3rem;
			}
		}
		.buttonleft {
			left: 0;
		}
	
		.buttonright {
			right: 0;
		}

	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 1300px) {
    .projectsViewer {
        flex-direction: column;
		height: auto;

        .projectItem {
            position: relative;
            flex-direction: column;
            width: 100%;
            display: block;
            opacity: 1;
        }

        button {
            position: absolute;
            top: calc(var(--header-size) + 4rem);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            background-color: transparent;

            img {
                width: 3rem;
                filter: invert(100%);
            }
        }

        .buttonleft {
            left: 0;
			z-index: 1;
        }

        .buttonright {
            right: 0;
        }

    }
}