.SignIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0), var(--secondary-color));
    background-repeat: no-repeat;
}

.Notification {
    width: 40%;
    margin-top: 30px;
    box-sizing: border-box;
    p{
        box-sizing: border-box;
        width: 100%;
        padding: 20px 50px;
        background-color: #F9F3EE;
        border: solid 2px #D9A963;
    }
}

.Notification.Error p{
    background-color: rgba(255,0,15,0.24);
    border-color: #D70000;
}
.Form, .Form label {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
}

.Form {
    width: 20%;
    margin: auto;
    padding: 50px;
    align-items: center;
    margin-block: 50px;
    input[type="file"]{
        display: none;
    }
    
    label {
        width: 100%;
        p {
            margin-bottom: 15px;
        }
    }
    input {
        height: 4rem;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    
    }
}

.Submit {
    display: flex;
    align-items: center;
    span {
        margin-inline: 20px;
    }
}