.ProjectsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: auto;
  height: 100%;
  padding: 2rem;
  justify-content: center;

  .addProjectButtonContainer {
    color: var(--text-color);
    border: 2rem;
    border-color: var(--element-color);
    max-width: 25rem;
    max-height:25rem;
    width: 100%;

    .addProjectButton {
      border-radius: 1rem;
      border-width: 0.3rem;
      border-style: solid;
      border-color: var(--element-color);
      background: none;
      width: 100%;
      height: 100%;
    }

    p {
      color: var(--text-color);
      font-size: 1.5rem;
    }

    img {
      width: 5rem;
      height: 5rem;

    }
  }

  .MappedItemContainer {
    display: flex;
    position: relative;
    max-width: 25rem;
    max-height: 25rem;
    background-color: var(--element-color);
    border-radius: 1rem;
    justify-content: center;
    align-items: center;

    .MappedItemContent {
        width: 90%;
        height: 90%;
        div{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-evenly;
            gap:0;
            padding: 0;
            p{
              font-size: 1rem;
            }
            h3 {
              font-size: 1rem;
            }
        }
        img {
          
            max-height: 50%;
        }

        .UpdateBtn{
          position: absolute;
          right:20%;
          bottom:2%;
        }
        .SupressBtn{
          position: absolute;
          right:2%;
          bottom:2%;
        }
    }
  }
}
.backButton{
  background:none;
  border:none;
  width: 0;
  height:0;
}
.modularBackground {
  display: flex;
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.801);
  z-index: 2;
  justify-content: center;
  align-items: center;
}

.modularContainer {
  position: absolute;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // margin-inline: auto;
  box-sizing: border-box;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--element-color);
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.89);
  border-radius: 1rem;
  z-index: 3;
  max-height:80vh;
  overflow: auto;
}