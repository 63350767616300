form {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    gap: 1rem;

    img{
      max-width : 20rem;
    }
  
    input {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
  
      &:focus {
        border-color: var(--secondary-color);
        outline: none;
      }
    }
  
    span {
      color: red;
      margin-bottom: 10px;
    }
    textarea{
      width: 100%;
      height:5rem;
      border-radius: 0.5rem;
      resize: none;

      &:focus {
        border-color: var(--secondary-color);
        outline: none;
      }
    }

    button {
      cursor: pointer;
      background-color: var(--secondary-color);
      color: var(--accent-color);
      border: none;
      height:2rem;
  
      &:hover {
        background-color: var(--primary-color);
        color: var(--text-color);
      }
    }
  }