.Footer {
    bottom: 0;
    margin-top: auto;
    padding: 2rem;
    background-color: transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    background-color: var(--primary-color);
}