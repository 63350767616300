
.ProjectItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;
  padding: 1rem;
  background-color: var(--element-color);
  border-radius: 1rem;
  color: var(--text-color);
  justify-content: space-between;

  img{
    border-radius: 1rem;
    max-width:100%;
  }
  .ProjectItemContent{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: auto;
    h3{
      font-size: 2rem;
      margin: 0;
    }
    p{
      font-size: 1rem;
      margin: 0;
    }
    a{
      color: var(--text-color);
      text-decoration: none;
      font-size: 1.2rem;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}


@media screen and (max-width: 1300px) {
  .ProjectItem{
    flex-direction: column-reverse;
    height: auto;
    padding: 1rem;

    img{
      max-width: 100%;
      max-height: 100%;
    }
    .ProjectItemContent{
      max-width: 60%;
    h3{
      font-size: 1rem;
    }
    p{
      font-size: 0.8rem;
    }
    a{
      font-size: 0.8rem;
    }
  }
  } 
  
}