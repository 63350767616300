.ContactLink{
    padding-top: var(--header-size);
    padding-bottom: var(--header-size);
    .ContactContainer{
        margin-inline: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: var(--element-color);
        border-radius: 1rem;
        box-shadow:  0 1rem 2rem rgba(0, 0, 0, 0.726);
        margin-top: 1rem;
        padding: 1rem;
        max-width:51rem;

        form {
            display: flex;
            flex-direction: column;
            
            max-width: 25rem;
            width: 100%;
            align-items: center;
        
            label, input{
                min-width: 90%;
                max-width: 20rem;
            }
            textarea {
                height:auto;
                min-width: 90%;
                max-width: 20rem;
                height: 20rem;
            }
            button{
                border-radius: 0.5rem;
            }
        }
        .ContactInfo {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            width: 100%;
            max-width: 20rem;
            h2 {
                font-weight: 400;
                font-size: 2rem;
            }
            p {
                font-family: var(--font-style);
                font-size: 1rem;
            }
            a {
                text-decoration: none;
                color: var(--secondary-color);
                font-weight: 700;
            }
        }
    }

    
}

@media screen and (max-width: 1300px) {
    .ContactLink{
        
        .ContactContainer{
            width:80%;
            align-items: center;
            margin-inline: auto;
            flex-direction: column-reverse;
            padding:1rem;
        }
        h2 {
            font-weight: 200;
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
        }
        a {
            text-decoration: none;
            color: var(--secondary-color);
            font-weight: 700;
        }
    }
    
}