.Container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: var(--element-color);
    padding:1rem;
    font-family: var(--font-style);

    img{
        height: 70rem;
        &.onclick{
            height: 100rem;
        }
    }

    .Skills {
        display: flex;
        flex-direction: column;
    
        .bar {
            width: 1rem;
            height: 1rem;
            border: 1px solid var(--secondary-color);
            background-color: var(--secondary-color);
            opacity: 0.3;
            transition: width 2s ease;
        }
        .barContainer{
            width: 100%;
            background-color: var(--text-color);
        }
        .filled {
            animation: fill 1s forwards;
            background-color: var(--accent-color);
            opacity: 1;
        }
        
        h2 {
            font-size: 3rem;
        }
        
        p {
            font-family: "Jakarta", sans-serif;
            margin:0;
        }
    
        @keyframes fill {
            from {
                width: 0;
            }
            to {
                width: var(--fill-percent);
            }
        }
    
    }
}

@media screen and (max-width: 1300px){
	.Container {
        flex-direction: column;
        gap: 4rem;
        align-items: center;
        .Skills{
            max-width: 90%;
            width:90%;
            h2 {
                font-size:2rem;
            }
        }
        img{
            max-width: 90%;
            height: auto;
        }
    }
}